import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Sub_header from '../Sub_header';
import Left_panel from "../Left_panel";
import { sub } from "date-fns";
import { useNavigate } from "react-router-dom";
import Moment from 'react-moment';

const Weight_discrepencies_dispute = () => {
    let navigate = useNavigate()
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
  
    const [state, setState] = useState({ isLoading: true, user_list: [] });
    const [otherStates, setOtherState] = useState({ dstatus: 4, activePage: 1, rowsPerPage: 100, page: 0, total_count: 0, onload: true });
    const [isLoading, setIsLoading] = useState(false);
   
  
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[viewModal, setviewModal] = React.useState({show:false , sub:{}})
    const [disputeText, setDisputeText] = React.useState('');
    const [images, setImages] = React.useState([]);
    const [videos, setVideos] = useState([]);
const[disablebutton , setdisablebutton] = React.useState(false)

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    useEffect(() => {
        getUsersList(0, true);
    }, [otherStates.dstatus])

    useEffect(() => {
        getUsersList(0, true);
    }, [])

    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/weight_discrepancies_dispute_list`;
        let sendData = { find: "", discrepencies_status: otherStates.dstatus, limit: otherStates.rowsPerPage, indexValue: index };

      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
         
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.count })

            }
            setState({ ...state, user_list: res.data.output, isLoading: false })



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const handlePageChange = (event, newPage) => {
        setOtherState({ ...otherStates, page: newPage })
        //console.log("newPage ", newPage);
        getUsersList(newPage, true, otherStates.rowsPerPage);
    }
    const handleChangeRowsPerPage = (event) => {
        //console.log("event ", event.target.value);        
        setOtherState({ ...otherStates, rowsPerPage: event.target.value })
        getUsersList(0, true, event.target.value)
    }

    const statusChange = (status) => {
        setOtherState({ ...otherStates, dstatus: status })
    }
  
    
    const changedisputestatus = (item,status) => {
   let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/weight_discrepancies_dispute_status_update`;
        let sendData = { shipment_id:item.shipment_id , order_number:item.order_number , customer_id:item.customer_id , status:status};

      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          if(res.data.status==true){
            Toast.fire({
                background: "#206bc4",
                type: 'success',
                title: "Updated Successfully",
                color: "white"
            });
            getUsersList(0,true)
          }
          else{
            Toast.fire({
                            background: "#e63900",
                            type: 'error',
                            title: "Something Went Wrong",
                            color: "white"
                        });
          }

        }).catch((e) => {
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color: "white"
            });

        });
    }
    const handleImageUpload = (e) => {
        const uploadedImages = Array.from(e.target.files);
      
        // Check the number of selected images
        if (uploadedImages.length + images.length > 3) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "You can only upload up to 3 images.",
          });
          return;
        }
      
        // Check the total size of selected images
        const totalSize = uploadedImages.reduce((acc, img) => acc + img.size, 0);
        if (
          (uploadedImages.length === 1 && totalSize > 3 * 1024 * 1024) ||
          (uploadedImages.length === 2 && totalSize > 6 * 1024 * 1024) ||
          (uploadedImages.length === 3 && totalSize > 10 * 1024 * 1024)
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Total image size exceeds the allowed limit.",
          });
          return;
        }
      
        setImages(prevImages => [...prevImages, ...uploadedImages]);
      };
      const handleVideoUpload = (e) => {
        const uploadedVideos = Array.from(e.target.files);
      
        // Check the number of selected videos
        if (uploadedVideos.length + videos.length > 3) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "You can only upload up to 3 videos.",
          });
          return;
        }
      
        // Check the total size of selected videos
        const totalSize = uploadedVideos.reduce((acc, vid) => acc + vid.size, 0);
        if (
          (uploadedVideos.length === 1 && totalSize > 30 * 1024 * 1024) ||
          (uploadedVideos.length === 2 && totalSize > 60 * 1024 * 1024) ||
          (uploadedVideos.length === 3 && totalSize > 100 * 1024 * 1024)
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Total video size exceeds the allowed limit.",
          });
          return;
        }
      
        setVideos(prevVideos => [...prevVideos, ...uploadedVideos]);
      };
      
      
      const removeImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
      };
      
      const removeVideo = (index) => {
        setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
      };

      const handleupdateSubmit = () => {
        setdisablebutton(true)
        submitupdateDispute(disputeText, images , videos);
      };
      const submitupdateDispute = async (disputeText, images, videos) => {
        if (disputeText === "") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Please enter dispute issue",
          });
          setdisablebutton(false)
          return;
         }
      
        const formData = new FormData();
        formData.append('disputeText', disputeText);
        formData.append('customerid', viewModal.sub.customer_id);
        formData.append('shipmentid', viewModal.sub.shipment_id);
      
        images.forEach((image, index) => {
          formData.append(`image${index}`, image);
        });
      
        videos.forEach((video, index) => {
          formData.append(`video${index}`, video);
        });
      
        try {
          const response = await axios.post(exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + '/update_admin_submit_dispute', formData, { headers: exportValue.headers});
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Success...',
              text: "Dispute updated successfully!",
            });
            handlecloseviewmodals();
            setdisablebutton(false)
            getUsersList(0,true)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Failed to update dispute. Please try again.",
            });
            setdisablebutton(false)
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "An error occurred. Please try again.",
          });
          setdisablebutton(false)
        }
      };
      const handlecloseviewmodals = ()=>{
        setviewModal({show:false,sub:{}})
        setDisputeText('')
        setImages([])
        setVideos([])
      }
    return (

<div>

       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
          
            <Sub_header/>
        <div class="row py-4">
        <div class="col-12 col-md">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li class="breadcrumb-item">
                        <a href="/">
                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Discrepancies Dispute</li>
                </ol>
            </nav>
            <h2 class="h4">Discrepancies Dispute</h2>
        </div>
        <div class="col-12 col-md-5">
            
        </div>
        {/* <div class="col-12 col-md text-end">
            <div class="">
                <div class="input-group ">
                    <span class="input-group-text">
                    <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                        </svg>
                    </span>
                    <input type="text" class="form-control" placeholder="Search Customers" name="stitle" value={searchfilters.searchtitle} onChange={(e) => stateHandler(e)} />
                </div>
            </div>
        </div> */}
        </div>
    
            
            <section>
            <ul class="nav nav-tabs justify-content-end">
                              
            <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 4 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(4)}>All</a>
                              </li>
                              <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 0 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(0)}>Action Required</a>
                              </li>
                              <li class="nav-item">
                                 <a class={"nav-link" + (otherStates.dstatus == 1 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(1)}>Dispute Raised</a>
                              </li>
                              <li class="nav-item">
                                  <a class={"nav-link" + (otherStates.dstatus == 2 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(2)}>Accepted</a>
                              </li>
                              <li class="nav-item">
                                  <a class={"nav-link" + (otherStates.dstatus == 3 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(3)}>Closed</a>
                              </li>
                              
                            
                 </ul>
            </section>
            {(isLoading) ?
                        <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                            <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                                <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                            </div>
                        </div> :
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                           
                            <th class="border-gray-200">Updated On</th>	
                            <th class="border-gray-200">AWB No.</th>
                            <th class="border-gray-200">Booking Weight</th>
                            <th class="border-gray-200">Charged Amount</th>	
                            <th class="border-gray-200">Updated Weight & Dimensions</th>
                            <th class="border-gray-200">Final Charged Amount</th>
                            <th class="border-gray-200">Issue</th>
                            <th class="border-gray-200">Status</th>
                            <th class="border-gray-200">Action</th>


                          
                    
                        </tr>
                    </thead>
                    <tbody>
                    {state.user_list.map((sub, index) =>
                          <tr>
                          <td>
                            <p><small><Moment format="MMM DD YYYY">
                            {new Date(sub.discrepencies_date*1000)}
                 </Moment> - <Moment format="hh:mm:ss a">
                             {new Date(sub.discrepencies_date*1000)}
                 </Moment></small></p>
                          </td>
                          <td>
                            <strong><a href={`/shipment_detail/${sub.shipment_id}`} style={{textDecoration:"none"}}>{sub.carrier_tracking_number}</a></strong><br/>
                            <p>{sub.carrier_name}</p>
                          </td>
                        
                       
                        
                          <td class="location_box_239">
                            <p>{sub.total_weight}KG</p>
                         
                          </td>
                          <td>{sub.total_amount.toFixed(2)}</td>

                          <td>Dimensions - {sub.discrepencies_length?sub.discrepencies_length:sub.length} X {sub.discrepencies_width?sub.discrepencies_width:sub.width} X {sub.discrepencies_height?sub.discrepencies_height:sub.height}<br/>
                         Charged Weight - {sub.discrepencies_weight?sub.discrepencies_weight:sub.total_weight} KG</td>

                          <td>{sub.discrepencies_amount?sub.discrepencies_amount.toFixed(2):sub.total_amount.toFixed(2)}</td>
                         <td>{sub.discrepencies_dispute_status==0?
                       ""
                        :  <a onClick={()=>setviewModal({show:true , sub:sub})} style={{cursor:"pointer"}} class="btn btn-primary" >
                       View
                      </a>}</td>
                          

   
                         
                         
                          <td>
                            {sub.discrepencies_dispute_status==0?<span class="badge rounded-pill bg-warning fw-400">Action Required</span>:sub.discrepencies_dispute_status==1?<span class="badge rounded-pill bg-primary fw-400">Dispute Raised</span>:sub.discrepencies_dispute_status==2?<span class="badge rounded-pill bg-success fw-400">Accepted</span>:sub.discrepencies_dispute_status==3?<span class="badge rounded-pill bg-danger fw-400">Closed</span>:""}
                          </td>
                          <td>
                            {sub.discrepencies_dispute_status==1?
                            <>  <a className="btn btn-primary" onClick={(e)=>changedisputestatus(sub,2)}>Accept</a>
                            <a className="btn btn-primary ms-1" onClick={(e)=>changedisputestatus(sub,3)}>Close</a></>
                         

                            :sub.discrepencies_dispute_status==2?
                            <a className="btn btn-primary ms-1" onClick={(e)=>changedisputestatus(sub,3)}>Close</a>:""
                        }
                          </td>
                        </tr>
                    )}
                                                    
                    </tbody>
                </table>
                <TablePagination
                                component="div"
                                rowsPerPageOptions={[100,150,200,250]}
                                count={otherStates.total_count}
                                page={otherStates.page}
                                onPageChange={handlePageChange}
                                rowsPerPage={otherStates.rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}

                            />
                         
            </div> 
}     

    </section>
    <Modal show={viewModal.show} onHide={() => setviewModal({ show: false, sub: {} })}>
  <Modal.Header closeButton>
    <Modal.Title>View and Update Dispute</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="modal-body">
      
      {/* Display Dispute Text */}
      <div className="mb-4">
        <label>Customer Dispute Text:</label>
        {viewModal.sub.discrepencies_dispute_issue && viewModal.sub.discrepencies_dispute_issue.length>0?
        viewModal.sub.discrepencies_dispute_issue.map((item)=>(
          <textarea 
          className="form-control" 
          rows="3" 
          value={item} 
          disabled

          />
        )):""}
       </div>

      {/* Display Uploaded Images */}
      {viewModal.sub.discrepencies_dispute_images && viewModal.sub.discrepencies_dispute_images.length > 0 && (
        <div className="mb-4">
          <label>Customer Attached Images and Videos:</label>
          <div className="d-flex flex-wrap">
            {viewModal.sub.discrepencies_dispute_images.map((file, index) => (
              <div key={index} className="m-2">
                {file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.png') ? (
                  <img 
                    src={file} 
                    alt={`attachment-${index}`} 
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <video width="100" height="100" controls>
                    <source src={file}  />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      )}


{/* Display Admin Dispute Text */}
         <div className="mb-4">
      {viewModal.sub.admin_discrepencies_dispute_issue?  <label>Admin Dispute Text:</label>:""}
        {viewModal.sub.admin_discrepencies_dispute_issue && viewModal.sub.admin_discrepencies_dispute_issue.length>0?
        viewModal.sub.admin_discrepencies_dispute_issue.map((item)=>(
          <textarea 
          className="form-control" 
          rows="3" 
          value={item} 
          disabled

          />
        )):""}
       </div>

      {/* Display Uploaded Images */}
      {viewModal.sub.admin_discrepencies_dispute_images && viewModal.sub.admin_discrepencies_dispute_images.length > 0 && (
        <div className="mb-4">
          <label>Admin Attached Images and Videos:</label>
          <div className="d-flex flex-wrap">
            {viewModal.sub.admin_discrepencies_dispute_images.map((file, index) => (
              <div key={index} className="m-2">
                {file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.png') ? (
                  <img 
                    src={file} 
                    alt={`attachment-${index}`} 
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <video width="100" height="100" controls>
                    <source src={file} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <textarea
        className="form-control mb-4"
        placeholder="Reply customer issue..."
        value={disputeText}
        onChange={(e) => setDisputeText(e.target.value)}
        rows="4"
      />
  
      <div className="mb-4">
        <label className="form-label">Upload Images (up to 3)</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="form-control"
        />
      </div>

      
      <div className="d-flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="position-relative m-2">
            <img
              src={URL.createObjectURL(image)}
              alt={`uploaded-${index}`}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
        
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeImage(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>

    
      <div className="mb-4">
        <label className="form-label">Upload Videos (up to 3)</label>
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleVideoUpload}
          className="form-control"
        />
      </div>

    
      <div className="d-flex flex-wrap">
        {videos.map((video, index) => (
          <div key={index} className="position-relative m-2">
            <video
              src={URL.createObjectURL(video)}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              controls
            />
         
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeVideo(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>
      
    </div>
  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-secondary" onClick={() => setviewModal({ show: false, sub: {} })}>
      Close
    </button>
    <button className="btn btn-primary" onClick={() => handleupdateSubmit()}>
      Update Dispute
    </button>
  </Modal.Footer>
</Modal>

{/* <?php
include('footer.php');
?>   */}
</div>
    )
}

export default Weight_discrepencies_dispute
