import axios from 'axios';
import React, { useState } from 'react'
import Sub_header from '../Sub_header'
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar, Modal } from "react-bootstrap"
import exportValue from "../../apiconfig";
import { useNavigate, useParams } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
const Product_details_zone = () => {
  let navigate = useNavigate();
  const { product_id, carrier_id , source_city_id } = useParams();
  const [zonesave , setzonesave] = useState({zone_name:""})
  const [catstate , setcatstate]  = useState([])
  const [zonestate , setzonestate]  = useState([])
  const[ addpincode , setaddpincode] = useState({pincode:"",destination_category:"" , zone:"" , expected_time:"" , prepaid:0 , cod:0,reverse_pickup:0 , forward_pickup:0})
  const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
  const[sourcecitylist,setsourceListstate] = useState([])
  const[tempsourcecitylist,settempsourceListstate] = useState([])
  const [searchfilters, setsearchFilters] = React.useState({ searchtitle: ""})
  const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:100,page:0,total_count:0,onload:true});
  const[cpscstate , setcpsc] = React.useState({carrier_name:[] , product_name:[] , source_city_name:[]})
  const[stateLoading,setLoading]=React.useState({isLoading:true})
  const[modals , setModals]= React.useState({show:false})
  React.useEffect(()=>{
   
    source_destinationlist()
     sourcecityname()
   
  },[])


     const savezone = ()=>{
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_product_zone_add_api`;
      let sendData = {  product_id:product_id , carrier_id:carrier_id , city_id:source_city_id , zone_name:zonesave.zone_name }
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
        if (res.data.status == "success") {
          setzonesave({...zonesave,zone_name:""})
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
          })

          Toast.fire({
              background: "#206bc4",
              type: 'success',
              title: "Added Sucessfully",
              color: "white"
          });
      }
      else {
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
          })

          Toast.fire({
              background: "#206bc4",
              type: 'unsuccess',
              title: "Something went wrong",
              color: "white"
          });
      }
          }).catch((e) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
              })

              Toast.fire({
                  background: "#206bc4",
                  type: 'unsuccess',
                  title: "Something went wrong",
                  color: "white"
              });

          });
     }

     const handlezoneChnange = (e)=>{
      setzonesave({zone_name:e.target.value})
     }

     const category = ()=>{
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_product_zone_list_api`;
      let sendData = {  product_id:product_id , carrier_id:carrier_id , city_id:source_city_id }
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
               setcatstate(res.data.product_category)
               setzonestate(res.data.product_zone)

          }).catch((e) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
              })

              Toast.fire({
                  background: "#206bc4",
                  type: 'unsuccess',
                  title: "Something went wrong",
                  color: "white"
              });

          });
     }
     const source_destinationlist = (index=0)=>{
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/source_destination_list`;
      let sendData = {  product_id:product_id , carrier_id:carrier_id , city_id:source_city_id ,  indexValue:index,
        limit:otherStates.rowsPerPage }
        if(searchfilters.searchtitle != ""){
          sendData.find= searchfilters.searchtitle
      }
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
        if(index==0  && otherStates.onload) {
          setOtherState({...otherStates,total_count:res.data.count})         
}
               setsourceListstate(res.data.output)
               settempsourceListstate(res.data.output)

               setLoading({...stateLoading,isLoading:false})
             

          }).catch((e) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
              })

              Toast.fire({
                  background: "#206bc4",
                  type: 'unsuccess',
                  title: "Something went wrong",
                  color: "white"
              });

          });
     }
     const sourcecityname = ()=>{
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_carrier_city_mode_name`;
      let sendData = {  product_id:product_id , carrier_id:carrier_id , city_id:source_city_id 
       }
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
      
               setcpsc({carrier_name:res.data.carrier_name , product_name: res.data.product_name , source_city_name : res.data.source_city_name})
             

          }).catch((e) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
              })

              Toast.fire({
                  background: "#206bc4",
                  type: 'unsuccess',
                  title: "Something went wrong",
                  color: "white"
              });

          });
     }
     
     const handleChange =(e)=>{
        let namess = e.target.type
        console.log("type",namess)
          if(e.target.type=="text"){
            
         setaddpincode({...addpincode,[e.target.name]: e.target.value})
          }
          else if(e.target.type=="checkbox"){
            setaddpincode({...addpincode,[e.target.name]:(e.target.checked)?1:0})
          }
          else{
            setaddpincode({...addpincode,[e.target.name]: e.target.value})
          }

       }

       const savepincode = ()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_source_destination_add_api`;
        let sendData = {  product_id:product_id , carrier_id:carrier_id , source_city_id:source_city_id , destination_pincode:addpincode.pincode,destination_category_id:addpincode.destination_category , destination_zone_id:addpincode.zone , expected:addpincode.expected_time , prepaid:addpincode.prepaid , reverse_pickup:addpincode.reverse_pickup , forward_pickup:addpincode.forward_pickup ,cod:addpincode.cod }
        console.log("senddata,",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
          if (res.data.status == "success") {
           
            source_destinationlist()
            setModals({show:false})
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
  
            Toast.fire({
                background: "#206bc4",
                type: 'success',
                title: "Added Sucessfully",
                color: "white"
            });
        }
        else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
  
            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });
        }
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       }

       const uploadPincodeFile= () => {

        if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
           // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = uploadModalState.excel_fle[0].name;
           // console.log("fileName =======>                 ",fileName);
           
           // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
           // return 
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_source_city_pincode`;
         let bodyFormData = new FormData()
        // console.log("bb", editState);
        // bodyFormData.append("country", editState.country)
         bodyFormData.append("carrier_id", carrier_id)
         bodyFormData.append("product_id", product_id)
         bodyFormData.append("source_city_id", source_city_id)

        // bodyFormData.append("mob_country_code", editState.mob_country_code)

        
        bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
         
       // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
        console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);

            setUploadModalState({...uploadModalState, show: false });
          //  setEditState({ ...editState, state_name: "", state_id: "" })

           
            if (res.status == 200 && res.data.status == "success") {
             
               // setTimeout(getStateList(0,true), 5000);
               const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Bulk Import Process Starts",
                    color: "white"
                });
                setTimeout(()=>{
                  navigate(`/import_process/${product_id}/${carrier_id}/${source_city_id}`)
                },1000)
                
            }
            
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }

            // navigate('/categories')
            // let newCategory = res.data.category;
            //setState(state.concat([newCategory]))
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })
        });
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
   

}

const csvinputEditHandleChange = (e) => {        
  if (e.target.type == "file") {            
      setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
  } else {
      setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
  }
}

const handlePageChange = (event,newPage) =>{
  console.log("newpage",newPage)
  setOtherState({...otherStates,page:newPage})
  //console.log("newPage ", newPage);
  source_destinationlist(newPage);
  
  // searchUser(state.searchValue,newPage)
}
const handleChangeRowsPerPage = (event,newPage) => {
  console.log("event ", event);
  setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
 
}


const stateHandler = (e) => {    
  setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
}

const searchOnkeyPress = (e,val) =>{
  console.log("e=>    ",e);
  console.log("val=>    ",val);
  
  source_destinationlist()
  
}



const resetalldata = () => {


  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/reset_all_data`;
  let sendData = {carrier_id:carrier_id  ,source_city_id:source_city_id , product_id:product_id  };
  console.log("sendData",sendData)
 
  axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res ", res);
      const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#206bc4",
          type: 'success',
          title: "Updated Sucessfully",
          color:"white"
        });
    }).catch((e) => {
      const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 5000
        })
        
        Toast.fire({
          background:"#e63900",
          type: 'error',
          title: "Something Went Wrong",
          color:"white"
        });
         
      
         });
}

  return (
    <div>
      <Sub_header/>
      <div style={{marginLeft:"15px",marginRight:"15px"}}>
      <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                    

                    <div class="d-flex">
                        <div class="">
                            <h2 class="h4">{cpscstate.product_name.map((sub)=>(
                              sub.product_name
                            ))} </h2>
                        </div>
                        <div class="ps-2 ">
                            <h5><span class="badge rounded-pill bg-dark">Carrier: {cpscstate.carrier_name.map((sub)=>(
                              sub.carrier_name
                            ))} </span></h5>
                        </div>
                        <div class="ps-2 ">
                            <h5><span class="badge rounded-pill bg-warning">Mode: {cpscstate.product_name.map((sub)=>(
                              sub.mode
                            ))}</span></h5>
                        </div>
                    </div>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    <a href="#" class="btn btn-sm btn-gray-800 d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#newzonecreate" data-bs-backdrop="false">
                        <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                        New Zone
                    </a>
                </div>
                {/* <!-- Modal  New ZONE Create--> */}
<div class="modal" id="newzonecreate" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create New Zone</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Zone Name:</label>
          <input type="text" class="form-control" id="" placeholder="Enter Zone name" name="zone_name" onChange={(e)=>handlezoneChnange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={savezone}>Create & Save</button>
      </div>
    </div>
  </div>
</div>

            </div>
            
    </section>

    <section>
            <section>
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                    <a class="nav-link " href={`/product_details/${product_id}/${carrier_id}`}>Price Chart</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link active" href={`/product_details_source_city/${product_id}/${carrier_id}`}>Source City Management</a>
                    </li>
                </ul>
            </section>
        <div class="card border-0 shadow ">
          <div class="card-body">
            

            <section>
                <div class="row mb-3">
                    <div class="col-md-2">
                        <small>Source City</small>
                        <h5>{cpscstate.source_city_name.map((sub)=>(
                              sub.city_name
                            ))}</h5>
                    </div>
                    <div class="col-md-4 mx-auto">
                        {/* <div class="input-group">
                            <input class="form-control border-end-0 border " type="search"   placeholder="Search pincode"  onChange={(e)=>stateHandler(e)} />
                            <span class="input-group-append">
                                <button class="btn btn-outline-primary bg-white border-bottom-0 border ms-n5" type="button"  onClick={(event) => searchOnkeyPress(event,"click")}>
                                    <i class="fa fa-search"></i>
                                </button>
                            </span>
                        </div> */}
                         <div class="input-group me-2 me-lg-3 fmxw-400">
                       
                        <input type="text" class="form-control" placeholder="Search Pincodes" onChange={(e)=>stateHandler(e)} />
                        <span class="input-group-text" onClick={(event) => searchOnkeyPress(event,"click")}>
                            <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </div>
                    </div>
                    {/* add pincode model */}
                    <Modal show={modals.show} onHide={()=>setModals({...modals,show:false})}>
                      <Modal.Header closeButton>
                      <h4 class="modal-title">Add New Pincode</h4>
                      </Modal.Header>
                      <Modal.Body>
                      <div class="modal-body">
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >Pincode:</label>
          <input type="text" class="form-control" id="" placeholder="Enter Pincode" name="pincode" onChange={(e)=>handleChange(e)}/>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >Destination Category :</label>
          <select  class="form-control" id="" name="destination_category"onChange={(e)=>handleChange(e)} onFocus={()=> category()}>
            <option>Select</option>
           {catstate.map((sub)=>(
            <option value={sub.category_id}>{sub.category_name}</option>
           ))}
            </select>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >Zone :</label>
          <select  class="form-control" id="" placeholder="Enter Pincode" name="zone" onChange={(e)=>handleChange(e)}>
          <option>Select</option>
           {zonestate.map((sub)=>(
            <option value={sub.zone_id}>{sub.zone_name}</option>
           ))}
          </select>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >TIME:</label>
          <input type="text" class="form-control" id="text" placeholder="Enter estimated delivery time" name="expected_time" onChange={(e)=>handleChange(e)}/>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >PREPAID:</label>
          <div class="form-check form-switch  mb-2">
             <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  name="prepaid" onChange={(e)=>handleChange(e)}/>
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >COD:</label>
          <div class="form-check form-switch  mb-2">
             <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  name="cod" onChange={(e)=>handleChange(e)}/>
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >REVERSE PICKUP:</label>
          <div class="form-check form-switch  mb-2">
             <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  name="reverse_pickup" onChange={(e)=>handleChange(e)} />
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label" >FORWARD PICKUP:</label>
          <div class="form-check form-switch  mb-2">
             <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  name="forward_pickup" onChange={(e)=>handleChange(e)}/>
          </div>
        </div>
      </div>
                      </Modal.Body>
                      <Modal.Footer>
                      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={savepincode}>Save</button>
      </div>
                      </Modal.Footer>
                    </Modal>
                  


                    <div class="col-md text-end ">
                        <button type="button" class="btn btn-primary" onClick={()=>{setModals({...modals , show:true})}}>Add New Pincode</button>
                       

                        <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#import_pincode" data-bs-backdrop="false">Import Bulk Pincode</button>

                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#Resetpincode" data-bs-backdrop="false"  >RESET (Delete all Pincode)</button>
                        
                    </div>
                    {/* import pincode model */}
                    <div class="modal" id="import_pincode" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Bulk Pincode </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="https://kourier-admin-panel.s3.ap-south-1.amazonaws.com/excel_fle/source_pincode1680676782302.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
        <hr/>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner"  required="" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)} />
        </div>
      </div>
      <div class="modal-footer">
        <a class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
{/* <!-- Modal  Resetpincode--> */}
<div class="modal" id="Resetpincode" data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content ">
      <div class="modal-header">
        <h4 class="modal-title">Are you sure?</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body ">
        <div class="alert alert-danger">
          <strong>Are you sure you want to do this action? It will reset all data.</strong> 
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={()=>resetalldata()}>Yes, I understand</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

                </div>
                <table class="table table-bordered">
                    <thead class="bg-dark">
                      <tr>
                        <th>DESTINATION PINCODE</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zone</th>
                        <th> Destination Category</th>
                        <th>Time</th>
                        <th>PREPAID</th>
                        <th>COD</th>
                        <th>REVERSE PICKUP</th>
                        <th>FORWARD PICKUP</th>
                        <th></th>
                      </tr>
                    </thead>
                    {(stateLoading.isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> :
                    <tbody>
                      {sourcecitylist.map((sub,index)=>(
                      <tr>
                        <td>{sub.destination_pincode}</td>
                        <td>{sub.city_name}</td>
                        <td>{sub.state_name}</td>
                        <td>{sub.zone_name}</td>
                        <td>{sub.category_name}</td>
                        <td>
                            {sub.expected}
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="prepaid" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                            </div>
                        </td>
                        <td class="pin_delte">
                            <button type="button" class="btn btn-danger btn-sm"><i class="material-icons-outlined pin_delte fs-12">delete</i> </button>
                        </td>
                      </tr>
                       ))}
                    </tbody>
}
                   
                </table>
            </section>

            <TablePagination
                component="div"
                rowsPerPageOptions={[100,150,200,250]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />


                
          </div>
        </div>
    </section>
      </div>
    </div>
  )
}

export default Product_details_zone
