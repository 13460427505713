import React from 'react'
import Sub_header from './Sub_header'

const Sms_gateway_settings = () => {
  return (
    <div>
      {/* <?php
include('header.php');
?>

<?php
include('sub_header.php');
?> */}

<Sub_header/>
<div style={{marginLeft:"15px",marginRight:"15px"}}>
<section>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div class="d-block mb-4 mb-md-0">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li class="breadcrumb-item">
                        <a href="/">
                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">SMS Settings</li>
                </ol>
            </nav>
            <h2 class="h4">SMS Gateway Settings</h2>
        </div>
        <div class="btn-toolbar mb-2 mb-md-0">
            {/* <!-- Additional buttons or toolbar content can go here --> */}
        </div>
    </div>
</section>

<section id="payment_gateway_info mb-3">
    <div class="row">
        <div class="col-12 col-md">
            <div class="card card-body border-0 shadow mb-5 ">
                <form>
                    {/* <!-- First radio button section --> */}
                    <div class="row kyc_settings">
                        <div class="row">
                            <div class="col-12 col-md-2">
                                <label class="custom-radio">
                                    <input type="radio" name="radioGroup" value="on" checked />
                                    <div class="radio-button">
                                        <div class="status-text"></div>
                                    </div>
                                    <div class="radio-label">MSG91</div>
                                </label>
                            </div>
                            <div class="col-12 col-md">
                                <div class="col-12 col-md">
                                    <div>
                                        <img src="./assets/img/msg91.svg" class="img-fluid img-thumbnail" />
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label for="email">Authkey</label>
                                            <div>
                                                <input class="form-control" id="first_name" type="text" placeholder="Enter Client authkey" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    {/* <!-- Second radio button section --> */}
                    <div class="row kyc_settings">
                        <div class="row">
                            <div class="col-12 col-md-2">
                                <label class="custom-radio">
                                    <input type="radio" name="radioGroup" value="off"/>
                                    <div class="radio-button">
                                        <div class="status-text"></div>
                                    </div>
                                    <div class="radio-label">Meta</div>
                                </label>
                            </div>
                            <div class="col-12 col-md">
                                <div>
                                    <img src="./assets/img/cashfree_logo.png" class="img-fluid img-thumbnail" />
                                </div>
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label for="email">Client secret ID</label>
                                        <div>
                                            <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret ID" />
                                        </div>
                                    </div>
                                    <div class="col-6 mb-3">
                                        <label for="email">Client secret key</label>
                                        <div>
                                            <input class="form-control" id="first_name" type="text" placeholder="Enter Client secret key"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Save button --> */}
                    <div class="mt-3">
                        <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                    </div>
                </form>
            </div>

            <h5>Module Settings</h5>
            <small>Set the module you want to keep enable for sending sms.</small>
            <div class="card card-body border-0 shadow mb-4 mt-3">
                <form>
                    <div class="row">
                        <div class="col-md-1">
                            <div class="">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="mySwitch" name="darkmode" value="yes" checked />
                                    <label class="form-check-label" for="mySwitch"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <label for="email">Registration Verification </label>
                        </div>
                        <div class="col-md-3 text-end">
                            <label for="email">Approved Template ID </label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" id="text" placeholder="Enter approved template ID" />
                        </div>
                    </div>
                    
                    <div class="mt-3">
                        <button class="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                    </div>
                </form>
            </div>
        </div>


        <div class="col-12 col-md-4">
            <h6>SMS Gateway Settings</h6>
            <p>
                Configure these settings to enable SMS notifications within the system. 
            </p>
            <p>
                Exercise caution when updating any fields to avoid disrupting the module's functionality. Please ensure you verify the changes before finalizing them.
            </p>
        </div>
    </div>
</section>

</div>


    </div>
  )
}

export default Sms_gateway_settings
