import React, { useState, useEffect } from "react";
import Sub_header from './Sub_header'

import axios from "axios";


import exportValue from "../apiconfig";
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2'
const New_custromer_registration = () => {
  
    const [state, setstate] = useState({});
    
    React.useEffect(()=>{
        getCompanyDetail()
    },[])
    const getCompanyDetail = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/company_info_api`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
              console.log("companyData ", res);
            if (res.data.companyDetail.length > 0) {
                let companyData = res.data.companyDetail[0];
               
                setstate(companyData);
                // setstateLoad({isLoading:false})
                //  getStateList(companyData.country)
                // getCityList(companyData.state)
            }



        }).catch((e) => {


        });
    }
    const onChangeHandlerGeneral = (e) => {
        // console.log("e general name ",e.target.name);
          console.log("e general value ",e.target.type);
         const newdata = { ...state };
         console.log("state === " , newdata)
         if(e.target.name=="credit_new_customer"){
             newdata[e.target.name] = e.target.value
         }
         else{
            newdata[e.target.name] = e.target.value
         }
         newdata[e.target.name] = (e.target.type != "checkbox") ? e.target.value : e.target.checked;
         setstate(newdata);
     }
    
    
     const companySettingUpdate = (UpdateData) => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_company_api_new_customer`;
        let sendData = { company_id: state.company_id, credit_new_customer: state.credit_new_customer , limit_shipments:state.limit_shipments };
        //console.log("sendData   ", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            //console.log("res ", res); 
            if(res.data.status=="success"){
                // navigate('/system_settings')   
                getCompanyDetail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'success',
                    title: "Update Sucessfully",
                    color:"white"
                  });

            }       
           

        }).catch((e) => {

        });
    }
  return (
    <div>
    
        
 <Sub_header/>
       <div style={{marginLeft:"15px",marginRight:"15px"}}> 
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav>
                    <h2 class="h4">New Customer Registratoin Settings</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            
    </section>

    <section>
        <div class="row">
            <div class="col-12 col-xl-12">
                <div class="card card-body border-0 shadow mb-4">
                    {/* <form> */}
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Registration Bonous Credit</label>
                                    <input class="form-control" id="email" type="number" placeholder="Enter the bonous registration amount" required onChange={(e)=>onChangeHandlerGeneral(e)} name="credit_new_customer" value={state.credit_new_customer?state.credit_new_customer:""}/>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="d-flex text-danger border-end">
                                    <div class="py-3">
                                        <i class="material-icons-outlined">lightbulb</i>
                                    </div>
                                    <div class="ps-4">
                                        <small>
                                            . Customers will automatically receive this credit in their wallet upon registration through the system. <br />
                                            . You have the option to use the value '0' to offer no discount or bonus.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">New Customer Booking Limits</label>
                                    <input class="form-control" id="email" type="number" placeholder="Enter the number of shipment can be booked by new customer without KYC" onChange={(e)=>onChangeHandlerGeneral(e)} name="limit_shipments" required  value={state.limit_shipments?state.limit_shipments:""}/>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="d-flex text-danger border-end">
                                    <div class="py-3">
                                        <i class="material-icons-outlined">lightbulb</i>
                                    </div>
                                    <div class="ps-4">
                                        <small>
                                            . Customers can book shipments at a predetermined value without completing the KYC process after logging in.<br/>
                                            . You can utilize the value '0' to restrict any bookings without completing the KYC process.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" onClick={()=>companySettingUpdate()}>Save </button>
                        </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    </section>
    </div>
    </div>
  )
}

export default New_custromer_registration
