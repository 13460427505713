import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';




const Search_pincode = () => {
    const [searchPincode , setSearchPincode] = React.useState({pincode:""})
    const [isLoading , setIsLoading] = React.useState(true)
    const[pincodeList , setPincodeList] = React.useState([])
    // const { country_id, state_id,city_id } = useParams();
    let navigate = useNavigate();
    // console.log("country_id ", country_id, "--", state_id,"--",city_id);
     const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')

    const [state, setState] = useState([]);
    // const [modalState, setModalState] = useState({ show: false });
    // const [editModalState, setEditModalState] = useState({ show: false });
    // const [editState, setEditState] = useState({postal_id:"", additive: "",postal_code: "", city_id: "", state_id: "",country_id:"" });
    // const [newState, setNewState] = useState({ additive: "",postal_code:"" });
    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" })
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 100, page: 0, total_count: 0, onload: true,country_name:"",state_name:"",city_name:"" });
    // const [isLoading, setIsLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    useEffect(() => {
        getPostalcodesList(0, true);
    }, [])

    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: 'bottom-end',
    //     showConfirmButton: false,
    //     timer: 5000
    // })

    const getPostalcodesList = (index = 0, onLoad) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/search_postalcodes_list`;
        let sendData = { searchvalue: "", country_id: 244,  limit: otherStates.rowsPerPage, indexValue: index };
        console.log("search ", search);
        if (search != null && search.length > 0) {
            sendData.searchvalue = search
        } else if (searchfilters.searchtitle != "") {
            sendData.searchvalue = searchfilters.searchtitle
        }
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            setIsLoading(false)
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.count,country_name:res.data.country_name,state_name:res.data.state_name,city_name:res.data.city_name })

            }
            setState(res.data.output, { isLoading: false })
            // setcountryList(res.data.output)


        }).catch((e) => {


        });
    }


    // const inputHandleChange = (e) => {
    //     // console.log("e", e);
    //     // console.log("type", e.target.type);
    //     // console.log("name", e.target.name);
    //     // console.log("value", e.target.value);
    //     // console.log("file", e.target.files);
    //     if (e.target.type == "file") {
    //         setNewState({ ...newState, [e.target.name]: e.target.files });
    //     } else {
    //         setNewState({ ...newState, [e.target.name]: e.target.value });
    //     }
    // }

    // const inputEditHandleChange = (e) => {
    //     // console.log("e", e);
    //     // console.log("type", e.target.type);
    //     // console.log("name", e.target.name);
    //     // console.log("value", e.target.value);
    //     // console.log("file", e.target.files);
    //     if (e.target.type == "file") {
    //         setEditState({ ...editState, [e.target.name]: e.target.files });
    //     } else {
    //         setEditState({ ...editState, [e.target.name]: e.target.value });
    //     }
    // }

    // const editCityValue = () => {
    //     if (editState.additive != "" && editState.postal_code != "") {
    //         cityUpdate(editState)
    //     } else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Please fill fields!',

    //         })

    //     }
    // }

    // const cityUpdate = (bodyData) => {

    //     setIsLoading(true)
    //     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_postalcode`;
    //     // let bodyFormData = new FormData()
    //     // console.log("bb", editState);
    //     // bodyFormData.append("country", editState.country)
    //     // bodyFormData.append("country_id", editState.country_id)
    //     // bodyFormData.append("mob_country_code", editState.mob_country_code)

    //     // if (editState.flag != undefined &&editState.flag != '') {
    //     //     bodyFormData.append("country_flage", editState.flag[0]);
    //     // }
    //     console.log("bb", bodyData);
    //     axios.post(full_api, bodyData, {
    //         headers: exportValue.headers
    //     }).then((res) => {
    //         // setIsLoading(false)
    //         console.log("res ", res);

    //         setEditModalState({ show: false });
    //         setEditState({ ...editState, postal_id:"",additive: "", postal_code: "" })

    //         setTimeout(
    //             getPostalcodesList(0, true), 5000);
    //         if (res) {
    //             Toast.fire({
    //                 background: "#10b93b",
    //                 type: 'success',
    //                 title: "Updated Successfully !",
    //                 color: "white"
    //             });
    //         }
    //         else {
    //             Toast.fire({
    //                 background: "#e63900",
    //                 type: 'error',
    //                 title: "Something Went Wrong",
    //                 color: "white"
    //             });
    //         }

    //         // navigate('/categories')
    //         // let newCategory = res.data.category;
    //         //setState(state.concat([newCategory]))
    //     }).catch((e) => {
    //         setIsLoading(false)
    //         Toast.fire({
    //             background: "#e63900",
    //             type: 'error',
    //             title: "Something Went Wrong",
    //             color: "white"
    //         });
    //     });


    // }

    // const newCitysave = (e) => {

    //     try {
    //         if (newState.additive != ""&& newState.postal_code != "") {
    //             setIsLoading(true)
    //             let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_postalcode`;
    //             let bodyData = {additive:newState.additive,postal_code:newState.postal_code, city_id: city_id, country_id: country_id, state_id: state_id }
    //             //console.log("bb", bodyData);
    //             axios.post(full_api, bodyData, { headers: exportValue.headers }).then((res) => {
    //                 console.log("res ", res);
    //                 //setIsLoading(false)
    //                 setModalState({ show: false });
    //                 setNewState({ ...newState, city_name: "" })

    //                 setTimeout(
    //                     getPostalcodesList(0, true, otherStates.rowsPerPage), 5000);
    //                 if (res) {
    //                     Toast.fire({
    //                         background: "#206bc4",
    //                         type: 'success',
    //                         title: "Added Successfully !",
    //                         color: "white"
    //                     });
    //                     // Swal.fire(
    //                     //     'Good job!',
    //                     //     ' Added Successfully !',
    //                     //     'success'
    //                     // )
    //                 }
    //                 else {
    //                     Toast.fire({
    //                         background: "#e63900",
    //                         type: 'error',
    //                         title: "Something Went Wrong",
    //                         color: "white"
    //                     });

    //                     // Swal.fire({
    //                     //     icon: 'error',
    //                     //     title: 'Oops...',
    //                     //     text: 'Something went wrong!',

    //                     // })
    //                 }

    //                 // navigate('/categories')
    //                 // let newCategory = res.data.category;
    //                 //setState(state.concat([newCategory]))
    //             }).catch((e) => {

    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Oops...',
    //                     text: 'Something went wrong!',

    //                 })
    //             });
    //         } else {
    //             setIsLoading(false)
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Oops...',
    //                 text: 'Please fill fields!',

    //             })

    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }

    // }

    // const newCityModel = () => {
    //     setNewState({ city_name: "" });
    //     setModalState({ show: true })
    // }

    // const edit_city = (data) => {
    //     console.log("edit", data);
    //     setEditState({postal_id:data.postal_id, additive: data.additive,postal_code: data.postal_code,city_id: data.city_id, state_id: state_id, country_id: country_id });
    //     setEditModalState({ show: true })

    // }

    const stateHandler = (e) => {
        // state[key] = value
        if (e.target.name == "stitle") {

            params.delete('search')
            search = null;
            setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
            //   navigate({ search: params.toString() })

        }
        //    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
        setOtherState({ ...otherStates, onload: true })
    }

    const filter = () => {
        setIsLoading(true)
        search = null
        params.delete('search')
        if (searchfilters.searchtitle !== "") {
            params.append('search', searchfilters.searchtitle)
        }

        // setState({ ...state, isLoading: true })
        navigate({ search: params.toString() })
        getPostalcodesList(0,true)
    }

    // const handleChange = (e, data,type) => {
    //     console.log("id---> ", type);

    //      console.log("e---> ", e.target.name);
    //     // console.log("e----> ", e.target.checked);
    //     cityUpdate({ country_id: country_id, state_id: state_id,postal_id:data.postal_id, city_id: data.city_id, [e.target.name]: (e.target.checked) ? 1 : 2 })
    // }

    const deleteConfirm = (city_id,postal_id, state_id,country_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            //console.log("result  ",result);
            if (result.isConfirmed) {
                setIsLoading(true)
                cityDelete(city_id,postal_id , state_id,country_id)
            }
        })

     }

    const cityDelete = (city_id,postal_id , state_id , country_id) => {
        try {
            if (country_id != "") {
                let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/delete_pincode_api`;
                let bodyFormData = { city_id: city_id, state_id: state_id, country_id: country_id , postal_id:postal_id}

                console.log("bb", bodyFormData);
                axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
                    console.log("res ", res);



                    setTimeout(
                        getPostalcodesList(0, true), 5000);
                    if (res) {
                        Swal.fire(
                            'Good job!',
                            ' Deleted Successfully !',
                            'success'
                        )
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',

                        })
                    }

                    // navigate('/categories')
                    // let newCategory = res.data.category;
                    //setState(state.concat([newCategory]))
                }).catch((e) => {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                });
            } else {
                setIsLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'something went wrong!',

                })

            }
        } catch (e) {
            console.log(e);
        }

    }

    const handlePageChange = (event, newPage) => {
        setOtherState({ ...otherStates, page: newPage })
        //console.log("newPage ", newPage);
        getPostalcodesList(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        console.log("event ", event);
    }

    // const searchOnkeyPress = (e) => {
    //     console.log("e=>    ", e.charCode);
    //     if (e.charCode == 13) {
    //         filter()
    //     }
    // }
// const stateHandler =(e)=>{
//     setSearchPincode({pincode:e.target.value})
// }

// const filter =()=>{
//     // const getPostalcodesList = (index = 0, onLoad) => {


//         let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/search_postalcodes_list`;
//         let sendData = { searchvalue: "", pincode:searchPincode.pincode};
//         // console.log("search ", search);
       
//         console.log("bb", sendData);
//         axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
//             console.log("res ", res);
//            if(res.data.status ==true){
//             setPincodeList(res.data.output)
//             setIsLoading(false)
//            }
           


//         }).catch((e) => {


//         });
//     // }
// }
    return (
        <div>
            {/* <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>New Pincode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Location</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="additive" onChange={(e) => inputHandleChange(e)} required />
                            <span>*Please fill </span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Postal Code</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter code" name="postal_code" onChange={(e) => inputHandleChange(e)} required />

                        </div>
                    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            newCitysave()
                        }>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* <Modal show={editModalState.show} onHide={() => setEditModalState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit State</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Location</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="additive" onChange={(e) => inputEditHandleChange(e)} required value={editState.additive} />
                            <span>*Please fill </span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Postal Code</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter code" name="postal_code" onChange={(e) => inputEditHandleChange(e)} required value={editState.postal_code} />

                        </div>
                       
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            editCityValue()
                        }>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal> */}
          
            {(isLoading) ?
                <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : ""}
                <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                        </a>
                                    </li>
                                    {/* <li className="breadcrumb-item active" aria-current="page">{otherStates.country_name} / {otherStates.state_name} / {otherStates.city_name}</li> */}
                                </ol>
                            </nav>
                            <h2 className="h4">Pincodes</h2>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="input-group me-2 me-lg-3 fmxw-400">
                                <span className="input-group-text">
                                    <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                                <input type="text" className="form-control" placeholder="Search Pincodes" name="stitle"  onChange={(e) => stateHandler(e)} onKeyPress={(e) => {
            if (e.key === "Enter") {
                filter(e);
            }
        }}/>
                            </div>
                            {/* <a className="btn btn-sm btn-gray-800 d-inline-flex align-items-center" onClick={() => newCityModel()}>
                                <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                New Pincode
                            </a> */}
                        </div>
                    </div>


                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="border-gray-200">Pincode</th>
                                    <th className="border-gray-200">LOCATION</th>
                                    <th className="border-gray-200">City</th>
                                    <th className="border-gray-200">State</th>
                                    <th className="border-gray-200">Country</th>
                                    {/* <th className="border-gray-200">Status</th> */}
                                    <th className="border-gray-200">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {state.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                           
                                            <td><span className="fw-bold">{item.postal_code}</span></td>
                                            <td><span className="fw-bold">{item.additive}</span></td>

                                          
                                            <td>
                                                <span className="fw-bold">{item.city_name}</span>
                                            </td>
                                            <td>
                                            <span className="fw-bold">{item.state_name}</span>
                                            </td>
                                            <td>
                                            <span className="fw-bold">{item.country_name}</span>
                                            </td>
                                             {/* <td>
                                                <div className="form-check form-switch  mb-2">
                                                    <input className="form-check-input" type="checkbox" name="status" role="switch" id="flexSwitchCheckChecked" defaultChecked={item.status == 1} onChange={(e) => handleChange(e, item,"status")}/>
                                                </div>
                                            </td>  */}
                                            <td>
                                                <div className="btn-group">
                                                    <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm">
                                                            <span className="fa fa-ellipsis-h icon-dark"></span>
                                                        </span>
                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu py-0">
                                                        
                                                        {/* <a className="dropdown-item" onClick={() => edit_city(item)}><span className="fa fa-edit me-2"></span>Edit</a> */}
                                                        <a className="dropdown-item text-danger rounded-bottom" onClick={() => deleteConfirm(item.city_id,item.postal_id,item.state_id , item.country_id)}><span className="fa  fa-trash me-2"></span>Remove</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[100,150,200,250]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        {/* <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <nav aria-label="Page navigation example">
                <ul className="pagination mb-0">
                    <li className="page-item">
                        <a className="page-link" href="#">Previous</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item active">
                        <a className="page-link" href="#">2</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#">3</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#">4</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#">5</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" href="#">Next</a>
                    </li>
                </ul>
            </nav>
            <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
        </div> */}
                    </div>


                </section>
           
        </div>

    )
}
export default Search_pincode